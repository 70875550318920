import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Anfadevs from "../../Assets/Projects/anfadevs-project.webp";
import MoroccoRoamer from "../../Assets/Projects/morocco-roamer-project.webp";
import SultanaLuxe from "../../Assets/Projects/sultanaluxe.webp";

function Projects({ isHome }) {
  return (
    <Container fluid className={`project-section ${isHome ? 'projects-home-bg' : ''}`}>
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MoroccoRoamer}
              isBlog={false}
              title="Morocco Roamer"
              description="An online touristique guide to discover Morocco, it showcases the beauty of Moroccan cities and it lists special Moroccan rugs to sell."
              projectLink="https://moroccoroamer.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Anfadevs}
              isBlog={false}
              title="Anfadevs"
              description="Anfadevs is a digital agency, it provides the best web services: Web design, web development and SEO."
              projectLink="https://anfadevs.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SultanaLuxe}
              isBlog={false}
              title="Sultana Luxe"
              description="A brand specializing in women's fashion: clothing, perfumes, accessories, and more."
              projectLink="https://sultanaluxe.com"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
