import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi there, I am <span className="purple">ACHERMOUK Mohamed </span>
            from <span className="purple"> Casablanca, Morocco.</span>
            <br />
            I am currently employed as a web developer at AutoDealers Digital.
            <br />
            I studied software engineering at both ENSA Safi and ISTA Tinghir.
            <br />
            <br />
            Apart from developing websites for my clients, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Reading business and self-help books
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing blogs about <a href="https://moroccoroamer.com">tourisme in Morocco</a>
            </li>
            <li className="about-activity">
              <ImPointRight /> Running
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "If the why is powerful, the how is easy"{" "}
          </p>
          <footer className="blockquote-footer">Jim Rohn</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
