import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub
} from "react-icons/ai";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              A FEW WORDS <span className="purple"> ABOUT </span> ME
            </h1>
            <p className="home-about-body">
               I am on a mission to build web solutions for big, medium or small business worldwide!
              <br />
              <br />I am proficient in:
              <i>
                <b className="purple"> Javascript, reactJs and PHP. </b>
              </i>
              <br />
              <br />
              <br />
              Whenever possible, I also apply my passion for developing websites
              with <b className="">WordPress</b> and
              <i>
                <b className="">
                  {" "}
                  other CMS solutions.
                </b>
              </i>
              &nbsp; like
              <i>
                <b className=""> SquareSpace and Joomla</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/MeedTodra"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
              <a
                href="https://wa.me/212642986546"
                target="_blank" 
                rel="noopener noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaWhatsapp />
              </a>
            </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mohamed-achermouk-845357224/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
